import React from "react"
import Modal from "react-modal"
import ReactMarkdown from "react-markdown"
import CustomTimeline from "../CustomTimeline"
// require('react-image-timeline/dist/timeline.css');

Modal.setAppElement("#___gatsby")

const CustomHeader = (props) => {
  const { title, date } = props.event

  return (
    <div className="custom-header">
      <h2 className="custom-header-h2">{title}</h2>
      {/* <h3>
        {date.getFullYear()}
      </h3> */}
    </div>
  )
}

const CustomTextBody = (props) => <span />

const CustomModal = (props) => {
  const { selected, events, modalIsOpen, closeModal } = props

  return (
    <Modal
      className="directormodal"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          // Change from absolute to fixed
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
        content: {
          position: "absolute",
          //border: '1px solid #ccc',
          //background: '#ccc',
          background: "rgba(98,98,98,1.0)",
          //overflowX: 'auto',
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          paddingBottom: "40px",
          paddingTop: "40px",
        },
      }}
    >
      <a href="#" className="close delete is-large" onClick={closeModal} />
      <div
        className="container"
        style={{
          width: `100%`,
        }}
      >
        <div className="columns is-mobile is-multiline">
          <div
            className="column is-4-desktop is-4-tablet is-12-mobile"
            style={{
              display: `flex`,
              alignItems: `center`,
              paddingLeft: `1.5rem`,
            }}
          >
            <img
              src={events[selected].imageUrl}
              style={{ top: `10px`, position: `sticky` }}
            />
          </div>
          <div
            className="director content column"
            id="corporate_history_text_modal"
            style={{
              overflowY: `scroll`,
              height: `410px`,
              marginBottom: `5px`,
              alignItems: `center`,
            }}
          >
            <div className="column is-full">
              <ReactMarkdown
                className="history_md_text"
                source={events[selected].text}
              />
              {/* <h5 className="subtitle is-size-6 has-text-weight-bold"
                  style={{marginBottom: '0rem'}}>
                  {events[selected].text}
                </h5> */}
            </div>
            {/* <div className="column is-full" >
              </div> */}
          </div>
        </div>
      </div>
    </Modal>
  )
}

// const CustomFooter = props => (
//   <span />
// )

class CorporateHistoryCard extends React.Component {
  constructor() {
    super()
    this.state = {
      modalIsOpen: false,
      selected: 0,
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(index) {
    // Add overflow hidden to <html> tag when modal is open
    document.querySelector("html").style.overflow = "hidden"
    this.setState({
      modalIsOpen: true,
      selected: index,
    })
  }

  afterOpenModal() {
    this.subtitle.style.color = "#f00"
  }

  closeModal = (e) => {
    e.preventDefault()
    // Remove overflow hidden to <html> tag when modal is open
    document.querySelector("html").style.overflow = "unset"
    // document.querySelector('html').style.removeProperty('overflow')
    this.setState({ modalIsOpen: false })
  }

  render() {
    const { histories } = this.props
    let events = []

    histories.map((history) => {
      events.push({
        date: new Date(history.year, 1, 1),
        title: history.title,
        imageUrl: history.cover,
        text: history.description,
        buttonText: "Read More",
        onClick: this.openModal,
      })
    })

    return (
      <React.Fragment>
        <CustomTimeline
          events={events}
          reverseOrder={true}
          customHeader={CustomHeader}
          customTextBody={CustomTextBody}
          customModal={CustomModal}
          selected={this.state.selected}
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
        />
      </React.Fragment>
    )
  }
}

export default CorporateHistoryCard
