import React from "react"
// import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PageWrapper from "../layouts/PageWrapper"
import CorporateHistoryCard from "../components/CorporateHistoryCard"
// import HomeLogo from '../../components/HomeLogo'
import HomeLogo from "../components/HomeLogo"
// require('react-image-timeline/dist/timeline.css');

export const CorporateHistoryTemplate = ({ histories }) => (
  <PageWrapper>
    <Helmet>
      <title>Corporate History</title>
    </Helmet>
    <section className="hero is-small" id="corporate_page-hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column">
              <HomeLogo />
              <h1 className="title" id="title-line">
                Corporate History
              </h1>
              {/* <a href="/management">
                  <div className="title is-5">Management Team</div>
                </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="hero" id="corporate_page-section">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-multiline">
            <CorporateHistoryCard histories={histories} />
          </div>
        </div>
      </div>
    </section>
  </PageWrapper>
)

export const CorporateHistoryPage = ({ data }) => {
  const { histories } = data.allMarkdownRemark.edges[0].node.frontmatter

  return <CorporateHistoryTemplate histories={histories} />
}

export default CorporateHistoryPage

export const corporateHistoryPageQuery = graphql`
  query CorporateHistoryPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "corporatehistory-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            histories {
              title
              cover
              year
              description
            }
          }
        }
      }
    }
  }
`
